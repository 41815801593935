<template>
  <div class="container">
    <div class="top-container" :class="bgArr">
      <div class="top">
        <img src="../../assets/images/history_day/title.png" />
        <div class="date">— {{ year }}.{{ month }}.{{ day }} —</div>
      </div>
    </div>
    <div v-if="!loading" class="list-container">
      <template v-for="(item, index) in content_list">
        <div v-if="item.length" class="item" :key="index">
          <div class="item-title">
            <div class="dec-icon"></div>
            <div>{{ map[index] }}</div>
          </div>
          <template v-if="index === 0">
            <div class="festival-container">
              <div
                v-for="(festival, idx1) in item"
                :key="'festival_' + index + '_' + idx1"
              >
                <div
                  class="festival-item"
                  v-show="
                    item.length < 10 ||
                    (item.length >= 10 && idx1 < 5) ||
                    visible[index]
                  "
                >
                  <div class="festival-name">{{ festival.name }}</div>
                  <div class="festival-desc">
                    {{ festival.content
                    }}<span
                      v-if="festival.link"
                      class="link"
                      @click="toDetail(festival.link_content_id)"
                      ><em></em>详情</span
                    >
                    <div class="img" v-if="festival.img_url">
                      <img
                        :src="festival.img_url"
                        @click="toDetail(festival.link_content_id)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="more"
                v-if="item.length >= 10 && !visible[index]"
                @click="showMore(index)"
              >
                展开全部
                <svg
                  width="10"
                  height="5"
                  viewBox="-2.5 -5 75 60"
                  preserveAspectRatio="none"
                  class="bottoma"
                >
                  <path
                    d="M0,0 l35,50 l35,-50"
                    fill="none"
                    stroke="#222"
                    stroke-linecap="round"
                    stroke-width="10"
                  />
                </svg>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="history-container">
              <div
                v-for="(history, idx2) in item"
                :key="'history_' + index + '_' + idx2"
              >
                <!-- 折叠逻辑：条数大于等于10条时，折叠为仅展示5条 -->
                <div
                  class="history-item"
                  v-show="
                    item.length < 10 ||
                    (item.length >= 10 && idx2 < 5) ||
                    visible[index]
                  "
                >
                  <div class="history-year">{{ history.year }}</div>
                  <div class="divider"></div>
                  <div class="history-content">
                    {{ history.content }}
                    <span
                      v-if="history.link"
                      class="link"
                      @click="toDetail(history.link_content_id)"
                      ><em></em>详情</span
                    >
                    <div class="img" v-if="history.img_url">
                      <img
                        :src="history.img_url"
                        @click="toDetail(history.link_content_id)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="more"
                v-if="item.length >= 10 && !visible[index]"
                @click="showMore(index)"
              >
                展开全部
                <svg
                  width="10"
                  height="5"
                  viewBox="-2.5 -5 75 60"
                  preserveAspectRatio="none"
                  class="bottoma"
                >
                  <path
                    d="M0,0 l35,50 l35,-50"
                    fill="none"
                    stroke="#9B6A31"
                    stroke-linecap="round"
                    stroke-width="10"
                  />
                </svg>
              </div>
            </div>
          </template>
          <img class="line" src="../../assets/images/festival/line.png" />
        </div>
      </template>
      <div class="item">
        <div class="item-title">
          <div class="dec-icon"></div>
          <div style="font-size: 18px">查看其他日期</div>
        </div>
        <div class="date-container">
          <div
            class="date"
            v-for="(d, i) in dayArr"
            :key="i"
            @click="switchDate(d)"
          >
            {{ d.substr(0, 2) }}月{{ d.substr(2) }}日
            <svg
              width="10"
              height="5"
              viewBox="-2.5 -5 75 60"
              preserveAspectRatio="none"
              class="righta"
            >
              <path
                d="M0,0 l35,50 l35,-50"
                fill="none"
                stroke="#9B6A31"
                stroke-linecap="round"
                stroke-width="10"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div v-show="showTop" class="icon-top" @click="toTop"></div>
  </div>
</template>
<script>
import moment from "moment";
import { Indicator } from "mint-ui";
import { getInfo } from "./api";
import tStatistic from "tstatistic";
tStatistic &&
  tStatistic.init({
    app_key: 61452902,
  });
export default {
  name: "index",
  data: function () {
    return {
      channel: "",
      date: "",
      year: moment().year(),
      month: "",
      day: "",
      loading: true,
      showTop: false,
      map: {
        0: "节日",
        1: "大事件",
        2: "出生名人",
        3: "逝世名人",
      },
      content_data: {
        date_show: "",
        festival: "",
        days_of_year: "",
        end_days: "",
        memorabilia: "",
        birth_celebrity: "",
        die_celebrity: "",
      },

      // 查看更多
      visible: {
        0: false,
        1: false,
        2: false,
        3: false,
      },
      //   三天前到三天后
      dayArr: [],

      // 图片数组
      picArr: ["bg1", "bg2", "bg3", "bg4"],
      bgArr: [],
    };
  },
  computed: {
    content_list() {
      let data = this.content_data;
      return [
        data.festival,
        data.memorabilia,
        data.birth_celebrity,
        data.die_celebrity,
      ];
    },
  },
  created() {
    document.title = '历史上的今天';

    localStorage.clear(); // 每次加载页面清空不同日期缓存的图片
    this.date = moment().format("MMDD");
    this.channel = this.$route.query.channel;
    try {
      tStatistic &&
        tStatistic.send({
          event: "page_view",
          md: 1,
          c_id: -1121,
        });
    } catch (e) {
      console.log(e);
    }
    this.init();
  },
  mounted() {
    this.screenH = window.screen.height;
    window.onscroll = (e) => {
      let y = e.path[1].scrollY;
      if (y > this.screenH) {
        this.showTop = true;
      } else {
        this.showTop = false;
      }
    };
  },
  methods: {
    async init() {
      this.loading = true;
      Indicator.open("加载中");
      let date = this.$route.query.date;
      if (date) this.date = date;
      this.month = this.date.substr(0, 2);
      this.day = this.date.substr(2);
      this.dayArr[0] = moment("" + this.year + this.date)
        .subtract("days", 3)
        .format("MMDD");
      this.dayArr[1] = moment("" + this.year + this.date)
        .subtract("days", 2)
        .format("MMDD");
      this.dayArr[2] = moment("" + this.year + this.date)
        .subtract("days", 1)
        .format("MMDD");
      this.dayArr[3] = moment("" + this.year + this.date)
        .add("days", 1)
        .format("MMDD");
      this.dayArr[4] = moment("" + this.year + this.date)
        .add("days", 2)
        .format("MMDD");
      this.dayArr[5] = moment("" + this.year + this.date)
        .add("days", 3)
        .format("MMDD");
      this.visible = {
        0: false,
        1: false,
        2: false,
        3: false,
      };
      const res = await getInfo({ date: this.date });
      if (res) {
        this.content_data = res.content_data;
        this.loading = false;
        Indicator.close();
      }
    },
    toTop() {
      scrollTo(0, 0);
    },
    switchDate(d) {
      this.$router.replace(
        `/history/index?date=${d}${
          this.channel ? "&channel=" + this.channel : ""
        }`
      );
      this.init();
      this.toTop();
    },
    showMore(index) {
      this.visible[index] = true;
    },
    toDetail(id) {
      if (id) this.$router.push(`/history/post?id=${id}`);
    },
    // 共四张背景，每次切换日期时从剩余未使用的图片中取一张，直到四张都被用完开始新的一轮随机。
    // 切换到已经查看过的日期会从缓存中取图片
    handleDateChange(date) {
      let cache_date = localStorage.getItem(date);
      if (cache_date) {
        this.bgArr = [cache_date];
      } else {
        if (!this.picArr.length) this.picArr = ["bg1", "bg2", "bg3", "bg4"];
        this.bgArr = [
          this.picArr.splice(Math.floor(Math.random() * this.picArr.length), 1),
        ];
        localStorage.setItem(date, this.bgArr[0]);
      }
    },
  },
  watch: {
    date(newV) {
      this.handleDateChange(newV);
    },
  },
};
</script>
<style lang="scss" scoped>
.top-container {
  position: relative;
  width: 100vw;
  height: 56vw;
  background-image: url("../../assets/images/history_day/top-bg1.png");
  background-size: cover;
  overflow: hidden;
  transition-duration: 0.2s;
  &.bg1 {
    background-image: url("../../assets/images/history_day/top-bg1.png");
  }
  &.bg2 {
    background-image: url("../../assets/images/history_day/top-bg2.png");
  }
  &.bg3 {
    background-image: url("../../assets/images/history_day/top-bg3.png");
  }
  &.bg4 {
    background-image: url("../../assets/images/history_day/top-bg4.png");
  }
  .top {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
    img {
      width: 43%;
    }
    .date {
      font-size: 16px;
      line-height: 30px;
      letter-spacing: 2px;
    }
  }
}
.container{
  background-color: #f7f5ef;
}
.list-container {
  letter-spacing: 0.5px;
  overflow: hidden;
  background-color: #f7f5ef;
  .item {
    padding: 0 15px;
    margin-bottom: 20px;
    .item-title {
      display: flex;
      justify-content: center;
      margin: 23px auto 24px;
      width: 197.8px;
      height: 42px;
      font-size: 22px;
      line-height: 42px;
      color: #9b6a31;
      font-weight: bold;
      text-align: center;
      background-image: url("../../assets/images/festival/sub-title-border.png");
      background-size: contain;
      background-repeat: no-repeat;
      div {
        flex: none;
      }
      .dec-icon {
        width: 14px;
        height: 14px;
        margin-top: 15px;
        margin-right: 5px;
        margin-left: -5px;
        background-image: url("../../assets/images/festival/dec-icon.png");
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    .line {
      width: 100%;
      margin: 12px 0 3px;
    }
  }
  .festival-container {
    .festival-item {
      margin-bottom: 15px;
      .festival-name {
        font-size: 22px;
        color: #9b6a31;
        margin: 10px 0;
        // font-weight: bold;
      }
      .festival-desc {
        font-size: 20px;
        color: rgba(0,0,0,0.6);
        .img {
          width: 100%;
          margin-top: 10px;
          img {
            width: 100%;
          }
        }
      }
    }
  }
  .history-container {
    color: #62625f;
    .history-item {
      display: flex;
      margin: 15px 0;
      font-size: 20px;
      .history-year {
        min-width: 0px;
        flex: none;
      }
      .divider {
        color: #999;
        height: 12px;
        border-left: 1px solid #62625f;
        margin: 0 10px;
        margin-top: 7px;
      }
      .img {
        margin-top: 10px;
        img {
          width: 80%;
        }
      }
    }
  }
  .date-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 10px;
    .date {
      width: 47%;
      flex: none;
      border: 1px solid #9b6a31;
      border-radius: 8px;
      text-align: center;
      color: #9b6a31;
      font-size: 20px;
      line-height: 40px;
      margin-bottom: 15px;
    }
  }
  .link {
    font-size: 20px;
    color: #4b7ee0;
    em {
      display: inline-block;
      width: 20px;
      height: 20px;
      background-image: url("../../assets/images/history_day/icon-link.png");
      background-size: contain;
      transform: translateY(4px);
    }
  }
  .more {
    width: 60vw;
    height: 36px;
    text-align: center;
    color: #9b6a31;
    font-size: 16px;
    line-height: 36px;
    border-radius: 18px;
    margin: 0 auto;
  }
  .righta {
    vertical-align: middle;
    transform: rotate(270deg);
    position: relative;
    top: -1px;
  }
  .bottoma {
    position: relative;
    top: -2px;
  }
}
.icon-top {
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 35px;
  right: 15px;
  background-image: url("../../assets/images/history_day/icon-top.png");
  background-size: contain;
  transition-duration: 0.2s;
}
</style>